import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Container, Row, Column } from '../elements/Grid';
import { LgTitle } from '../elements/Titles';
import {
  BaseParagraph,
  LgParagraph,
  XsParagraph
} from '../elements/Paragraphs';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { rem } from 'polished';

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      ribn: file(relativePath: { eq: "ribn.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      tophat: file(relativePath: { eq: "tophat.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      scottie: file(relativePath: { eq: "scottie.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      iron: file(relativePath: { eq: "iron.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      wiseads: file(relativePath: { eq: "wiseads.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      boot: file(relativePath: { eq: "boot.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="About MoneyWise" />
      <Container>
        <Row marginBottom="0">
          <Column>
            <LgTitle>About Us</LgTitle>
          </Column>
        </Row>
        <Row marginBottom="64">
          <Column>
            <BaseParagraph>
              Wise Publishing is a digital publisher and technology company —
              but we’re much more than that. We’re a group of talented,
              passionate people who believe online publishing tools should be
              more useful and productive, and that consumers deserve the best
              possible information to help them make smart choices and get
              ahead. (We’re serious about that stuff, but trust us — we know how
              to have fun, too.)
            </BaseParagraph>
            <BaseParagraph>
              Our core product is MoneyWise, our widely read, high-quality
              personal finance brand which serves the U.S. (MoneyWise.com) and
              Canada (Money.ca). We also operate Half Banked
              (halfbanked.com), a personal finance site that’s written for a
              millennial audience but has fans of all ages.
            </BaseParagraph>
            <BaseParagraph>
              In the UK we operate <a href="https://www.moneywise.co.uk">Moneywise.co.uk</a> with
              the same email and phone number as our other sites.
            </BaseParagraph>
            <BaseParagraph>
              We have appointed GRCI Law Limited to act as our UK Representative. Please see our UK
              contact page at <a href="https://moneywise.co.uk/contact">https://moneywise.co.uk/contact</a> for GRCI Law’s contact information.
            </BaseParagraph>
          </Column>
        </Row>
        <Row textAlign="center" marginBottom="64">
          <Column>
            <LgParagraph>
              <strong>Our Technology</strong>
            </LgParagraph>
          </Column>
        </Row>
        <Row>
          <Column width="1/3">
            <Img
              fluid={data.ribn.childImageSharp.fluid}
              style={{ width: rem('96px'), margin: '0 auto' }}
            />
            <BaseParagraph>Ribn</BaseParagraph>
            <XsParagraph>
              Ribn (ribn.com) is an affiliate marketing monetization platform.
              Ribn is SaaS and is presently available to other organizations at
              a fixed monthly cost.
            </XsParagraph>
          </Column>
          <Column width="1/3">
            <Img
              fluid={data.iron.childImageSharp.fluid}
              style={{ width: rem('96px'), margin: '0 auto' }}
            />
            <BaseParagraph>Iron</BaseParagraph>
            <XsParagraph>
              Iron is our multi-tenant front-end web application.
            </XsParagraph>
          </Column>
          <Column width="1/3">
            <Img
              fluid={data.tophat.childImageSharp.fluid}
              style={{ width: rem('96px'), margin: '0 auto' }}
            />
            <BaseParagraph>Top Hat</BaseParagraph>
            <XsParagraph>
              Top Hat is our business intelligence (BI) and native ad campaign
              management platform.
            </XsParagraph>
          </Column>
        </Row>
        <Row marginBottom="144">
          <Column width="1/3">
            <Img
              fluid={data.boot.childImageSharp.fluid}
              style={{ width: rem('96px'), margin: '0 auto' }}
            />
            <BaseParagraph>Boot</BaseParagraph>
            <XsParagraph>
              Boot is our content management system (CMS) and feed management
              platform.
            </XsParagraph>
          </Column>
          <Column width="1/3">
            <Img
              fluid={data.scottie.childImageSharp.fluid}
              style={{ width: rem('96px'), margin: '0 auto' }}
            />
            <BaseParagraph>Scottie</BaseParagraph>
            <XsParagraph>
              Scottie is our analytics platform and customer data platform
              (CDP).
            </XsParagraph>
          </Column>
          <Column width="1/3">
            <Img
              fluid={data.wiseads.childImageSharp.fluid}
              style={{ width: rem('96px'), margin: '0 auto' }}
            />
            <BaseParagraph>WiseAds</BaseParagraph>
            <XsParagraph>
              WiseAds is our Prebid header bidding wrapper.
            </XsParagraph>
          </Column>
        </Row>
      </Container>
    </Layout>
  );
};

export default About;
